@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px 0px 0px;
  background-color: #f5f7fa;  /* Fallback */
  background: 
    linear-gradient(135deg, #f5f7fa 0%, #a5bcd5 100%);
  background-attachment: fixed;
  position: relative;
  overflow: auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
  cursor: default !important;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 15% 50%, rgba(46, 62, 78, 0.15) 0%, transparent 50%),
    radial-gradient(circle at 85% 30%, rgba(178, 212, 245, 0.15) 0%, transparent 50%),
    repeating-linear-gradient(45deg, 
      rgba(255, 255, 255, 0.1) 0px, 
      rgba(255, 255, 255, 0.1) 2px, 
      transparent 2px, 
      transparent 8px,
      rgba(255, 255, 255, 0.1) 8px, 
      rgba(255, 255, 255, 0.1) 10px,
      transparent 10px,
      transparent 18px
    ),
    repeating-linear-gradient(-45deg, 
      rgba(255, 255, 255, 0.1) 0px, 
      rgba(255, 255, 255, 0.1) 2px, 
      transparent 2px, 
      transparent 8px,
      rgba(255, 255, 255, 0.1) 8px, 
      rgba(255, 255, 255, 0.1) 10px,
      transparent 10px,
      transparent 18px
    );
  pointer-events: none;
  z-index: 0;
}
